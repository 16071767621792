@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

:root {
  --background: white;
  --text: black;
  --title-color: rgb(30, 30, 30);
  --board-border: 1px solid rgb(174, 176, 179);
  --board-bg-color: rgba(252, 252, 252, 0.97);
  --board-text: black;
  --task-bg-color: #efefef;
  --task-border: none;
  --task-border-rad: 0px;
  --task-time-text: black;
  --task-edit-bg: rgb(249, 249, 249);
  --task-edit-border: 1px dashed rgb(225, 225, 225);
  --task-reminder: 5px solid rgb(228, 34, 34);
  --button-toggle-bg: rgb(202, 58, 58);
  --button-toggle-filter: brightness(92%);
  --submit-input-bg: rgb(31, 31, 31);
  --submit-input-color: white;
  --addtask-input-bg: hsl(0, 0%, 96%);
  --addtask-input-shadow:0 0 2px 1px rgb(231, 231, 231);
  --addtask-input-text: rgb(163, 163, 163);
  --modal-tab-highlight: 2px groove red;
  --link-accent-color: rgb(191, 31, 31);
  --checkbox-color: rgb(205, 57, 57);
  --modal-button-bg: rgb(251, 251, 251);
  --modal-button-border: 1px solid rgb(237, 237, 237);
}

html[data-theme="Blue / White"] {
  --background: white;
  --text: black;
  --title-color: rgb(30, 30, 30);
  --board-border: 1px solid rgb(150, 150, 151);
  --board-bg-color: rgba(252, 252, 252, 0.95);
  --board-text: black;
  --task-bg-color: rgb(248, 248, 250);
  --task-border: 1px solid rgb(160, 160, 160);
  --task-border-rad: 3px;
  --task-time-text: black;
  --task-edit-bg: rgb(249, 249, 249);
  --task-edit-border: 1px dashed rgb(225, 225, 225);
  --task-reminder: 5px solid skyblue;
  --button-toggle-bg: rgb(101, 145, 193);
  --button-toggle-filter: brightness(92%);
  --submit-input-bg: rgb(31, 31, 31);
  --submit-input-color: white;
  --addtask-input-bg: rgb(250, 250, 252);
  --addtask-input-shadow:0 0 0px 1px rgb(160, 160, 160);
  --addtask-input-text: rgb(119, 117, 117);
  --modal-tab-highlight: 2px groove skyblue;
  --link-accent-color: rgb(39, 131, 167);
  --checkbox-color: rgb(93, 195, 236);
  --modal-button-bg: rgb(251, 251, 251);
  --modal-button-border: 1px solid rgb(237, 237, 237);
}

html[data-theme="Red / White"] {
  --background: white;
  --text: black;
  --title-color: rgb(30, 30, 30);
  --board-border: 1px solid rgb(140, 141, 143);
  --board-bg-color: rgba(249, 249, 249, 0.95);
  --board-text: black;
  --task-bg-color: #ebebeb;
  --task-border: 1px solid rgb(141, 141, 141);
  --task-border-rad: 3px;
  --task-time-text: black;
  --task-edit-bg: rgb(249, 249, 249);
  --task-edit-border: 1px dashed rgb(225, 225, 225);
  --task-reminder: 5px solid rgb(228, 34, 34);
  --button-toggle-bg: rgb(202, 58, 58);
  --button-toggle-filter: brightness(92%);
  --submit-input-bg: rgb(31, 31, 31);
  --submit-input-color: white;
  --addtask-input-bg: #f1f1f1;
  --addtask-input-shadow:0 0 0px 1px rgb(128, 125, 125);
  --addtask-input-text: rgb(119, 117, 117);
  --modal-tab-highlight: 2px groove red;
  --link-accent-color: rgb(191, 31, 31);
  --checkbox-color: rgb(205, 57, 57);
  --modal-button-bg: rgb(251, 251, 251);
  --modal-button-border: 1px solid rgb(237, 237, 237);
}

html[data-theme="Black / Sand"] {
  --background: black;
  --text: white;
  --title-color: whitesmoke;
  --board-border: 1px solid rgb(128, 128, 123);
  --board-bg-color: rgba(8, 8, 8, 0.92);
  --board-text: rgb(230, 230, 230);
  --task-bg-color: rgb(0, 0, 0);
  --task-border: 1px solid rgb(152, 152, 152);
  --task-border-rad: 3px;
  --task-time-text: antiquewhite;
  --task-edit-bg: rgb(17, 17, 17);
  --task-edit-border: 1px dashed rgb(50, 50, 50);
  --task-reminder: 5px solid rgb(179, 165, 129); 
  --button-toggle-bg: rgb(177, 159, 119); 
  --button-toggle-filter: brightness(1.5);
  --submit-input-bg: rgb(180, 173, 137); 
  --submit-input-color: black;
  --addtask-input-bg: rgb(4, 4, 4);
  --addtask-input-shadow: 0 0 0px 1px rgb(128, 128, 123);
  --addtask-input-text: rgb(175, 175, 175);
  --modal-tab-highlight: 2px groove rgb(180, 173, 137);
  --link-accent-color: rgb(180, 173, 137); 
  --checkbox-color: rgb(198, 185, 120); 
  --modal-button-bg: rgb(20, 20, 20);
  --modal-button-border: 1px solid rgb(40, 40, 40);
}

html[data-theme="Black / Blue"] {
  --background: black;
  --text: white;
  --title-color: whitesmoke;
  --board-border: 1px solid rgb(89, 99, 119);
  --board-bg-color: rgba(10, 10, 10, 0.92);
  --board-text: rgb(230, 230, 230);
  --task-bg-color: rgb(0, 0, 0);
  --task-border: 1px solid rgb(121, 130, 153);
  --task-border-rad: 3px;
  --task-time-text: rgb(212, 217, 219);
  --task-edit-bg: rgb(20, 20, 20);
  --task-edit-border: 1px dashed rgb(50, 50, 50);
  --task-reminder: 5px solid rgb(39, 131, 167);
  --button-toggle-bg: rgb(55, 108, 144); 
  --button-toggle-filter: brightness(1.5);
  --submit-input-bg: rgb(47, 130, 185);
  --submit-input-color: black;
  --addtask-input-bg: #080b11;
  --addtask-input-shadow: 0 0 0px 1px rgb(110, 119, 141);
  --addtask-input-text: rgb(189, 189, 189);
  --modal-tab-highlight: 2px groove skyblue;
  --link-accent-color: rgb(39, 131, 167);
  --checkbox-color: rgb(93, 195, 236);
  --modal-button-bg: rgb(20, 20, 20);
  --modal-button-border: 1px solid rgb(40, 40, 40);
}

html[data-theme="Dark / Red"] {
  --background: #141617;
  --text: #f9f7f4;
  --title-color: #e4e0db;
  --board-border: 1px solid rgb(99, 99, 99);
  --board-bg-color: rgba(29, 30, 30, 0.97);
  --board-text: #f9f7f4;
  --task-bg-color: rgb(54, 56, 58);
  --task-border: none;
  --task-border-rad: 0px;
  --task-time-text: #f9f7f4;
  --task-edit-bg: #171b1c;
  --task-edit-border: 1px dashed rgb(119, 119, 119);
  --task-reminder: 5px solid #e42222;
  --button-toggle-bg: rgb(202, 58, 58);
  --button-toggle-filter: brightness(92%);
  --submit-input-bg: #262727;
  --submit-input-color: #f9f7f4;
  --addtask-input-bg: rgb(52, 53, 56);
  --addtask-input-shadow: 0 0 0px 1px rgb(56, 56, 56);
  --addtask-input-text: #e2dfd9;
  --modal-tab-highlight: 2px groove red;
  --link-accent-color: rgb(191, 31, 31);
  --checkbox-color: rgb(205, 57, 57);
  --modal-button-bg: #1b1d20;
  --modal-button-border: 1px solid rgb(49, 49, 49);
}

/* html body section */

body {
  font-family: 'Open Sans';
  height: 100%;
  margin: 0;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
}

.black-mode-body {
  background-color: black;
}

.black-mode-body-point {
  background-image: url("./pointing_meme_black.png");
}

.light-mode-body {
  background-color: white;
}

.light-mode-body-point {
  background-image: url("./pointing_meme_white.png");
}

.dark-mode-body {
  background-color: rgb(19,21,22);
}

/* Lunapic fill color #1a1c1d */
.dark-mode-body-point {
  background-image: url("./pointing_meme_dark.png");
}

/* Board section */

.board-container {
  width: auto;
  display: flex;
  padding-inline: 2%;
}

.flex-child {
  flex: 1;
  width: 1px;
  margin: 2%;
  border: var(--board-border);
  background-color: var(--board-bg-color);
  border-radius: 5px;
}

.board {
  max-width: 500px;
  overflow: hidden;
  min-height: 300px;
  padding: 15px;
  color: var(--board-text);
}

span {
  font-family: 'Montserrat';
}

.flex-child h2 {
  text-align: center;
  padding-bottom: 7px;
  font-weight: 500;
  margin-top: 10px;
}

/* Header section */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;
  min-width: 300px;
  max-width: 380px;
  margin: 0 auto;
}

.header-container {
  padding: 20px;
}

h1{
  padding-right: 0px;
  min-width: 240px;
  color: var(--title-color);
}

.header .buttons{
  display: inline-flex;
}

/* Button section */

.button {
  cursor: pointer;
}

.button.toggle {
  background: var(--board-bg-color);
  color: var(--button-toggle-bg);
  height: 35px;
  width: 55px;
  padding: 4px;
  margin: 5px;
  border: 1px solid var(--button-toggle-bg);
  border-radius: 3px;
  font-size: 20px;
  font-family: 'Ubuntu';
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.toggle.expanded {
  background: grey;
  background: var(--board-bg-color);
  filter: var(--button-toggle-filter);
  color: var(--button-toggle-bg);
}

.button.toggle:active {
  transform: scale(0.98);
  filter: var(--button-toggle-filter);
}


.button.close {
  border: none;
  background: none;
  color: rgba(130, 130, 130, 0.4);
}

#modal.button.close {
  font-size: 18px;
  position: absolute;
  right: 0;
  margin: 4px;
  padding: 0;
  z-index: 3;
} 

#modal.button.close:hover {
  visibility: visible;
  color: rgba(130, 130, 130, 0.8);
} 

button.modal {
  flex: 1;
  height: 100%;
  border: var(--modal-button-border);
  background-color: var(--modal-button-bg);
  color: var(--text);
  font-size: 18px;
  font-family: "montserrat";
}

button.modal.selected {
  border-bottom: none;
  background-color: inherit;
  border-top: var(--modal-tab-highlight);
}

/* Task section */

.task {
  background: var(--task-bg-color);
  border: var(--task-border);
  border-radius: var(--task-border-rad);
  margin: 5px;
  padding: 10px 20px;
  padding-right: 15px;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task.reminder {
  border-left: var(--task-reminder)
}

.task h3 {
  margin: 0;
  padding: 0;
  font-weight: 500;
  min-width: 75px;
}

.task p {
  margin: 0;
}

.task input {
  color: rgb(0, 140, 200);
  border: var(--task-edit-border);
  outline: none;
  background-color: var(--task-edit-bg);
  width: 65%;
  border-radius: 3px;
  font-size: large;
}

.task h5 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: var(--task-time-text);
}

.task-buttons {
  min-width: 80px;
  display: flex;
  justify-content: flex-end;
}

/* Add task section */

.add-form {
  padding: 0px;
  margin-left: 30%;
  margin-right: 30%;
}

.form-control {
  margin: 12px 0px;
  font-size: 17px;
}

.form-control label {
  display: block;
  color: var(--text);
  font-weight: 600;
}

.form-control input {
  width: 100%;
  height: 30px;
  margin-top: 6px;
  padding: 3px;
  font-size: 17px;
  box-sizing: border-box;
  background-color: var(--addtask-input-bg);
  color: var(--checkbox-color);
  border: 1px #f4f4f4;
  border-radius: 6px;
  -moz-box-shadow: var(--addtask-input-shadow);
  -webkit-box-shadow: var(--addtask-input-shadow);
  box-shadow: var(--addtask-input-shadow);
}

.form-control input::placeholder {
  color: var(--addtask-input-text);
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}

.form-control-check label {
  float: left;
  margin-right: 100px;
}

.form-control-check input {
  height: 15px;
  float: right;
  width: 15px;
  box-shadow: none;
}

.submit-input {
  display: block;
  width: 100%;
  color: var(--submit-input-color);
  background-color: var(--submit-input-bg);
  padding: 0;
  margin-top: 10px;  
  border: none;
  height: 30px;
  font-family: 'Ubuntu';
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}

/* Modal section */

.modal-bg {
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;  
  left: 0;
  cursor: pointer;
}

.popup {
  top: 18%;  
  max-width: 90%;
  min-height: 300px;
  height: 65%;
  aspect-ratio: 16 / 10;
  background: var(--background);
  color: var(--text);
  position: fixed;
  z-index: 100;
  border-radius: 2px;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 5px 2px rgba(80, 75, 75, 0.5);
  overflow: auto;
  cursor: default;
}

.modal-buttons {
  width: 100%;  
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  height: 15%;
  position: relative;
}

.modal-header span{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
}

/* Modal Content section */

.modal-content-container {
  padding-inline: 16px;
  padding-block: 8px;
}

.modal-content-container p{
  font-size: 16px;
  font-weight: normal;
  margin-top: 3px;
  margin-bottom: 0;
}

.theme-buttons {
  height: 25px;
  padding: 8px 8px 5px 6px;
  display: flex;
  padding-left: 0;
}

.theme-buttons button{
  background: transparent;
  padding: 0;
  margin-right: 6px;
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
}

svg {
  border: #181818;
  cursor: pointer;
}

.modal-content-container span{
  display: block;
  font-size: 13px;
  margin-bottom: 10px;
  font-style: italic;
}

.modal-content-container input {
  display: inline;
  height: 16px;
  width: 16px;
  padding-top: 2px;
  margin-left: 6px;
  position: absolute;
  accent-color: var(--checkbox-color);
}

p#check {
  display: inline;
}

.modal-content-container ul{
  font-size: 15px;
  margin: 0;
}

ul#top{
  margin-bottom: 8px;
}

.modal-content-container a{
  display: inline-block;
  margin-block: 10px;
  color: var(--link-accent-color);
}

p#opening {
  font-style: italic;
  margin-bottom: 15px;
  margin-top: 6px;
  text-align: center;
}

/* Resize section */
@media (max-width: 1000px) 
{
  .board-container {
    flex-direction: column;
  }

  .flex-child {
    flex: 1;
    width: auto;
    margin-inline: 10%;
    margin-block: 10px;
  }

  .board {
    min-height: 80px;
    margin: 0;
    max-width: none;
    padding-top: 10px;
  }

  .flex-child h2 {
    text-align: center;
    padding-bottom: 7px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 0;
    width: 100%;
  }

  .header-container {
    padding: 0;
  }

  .header {
    width: 400px;
  } 

  .add-form {
    margin-inline: 8%;
    margin-bottom: 20px;
  }

  .add-form .form-control{
    margin-top: 0;
  }
}

@media (max-width: 420px) {
  .header {
    width: 100px;
    display: flex;
    flex-direction: column;
  } 

  .header h1{
    margin-bottom: 0;
  } 
}